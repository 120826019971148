import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/thumbs';
import {EffectCards, Thumbs} from 'swiper/modules';
import {useLocation} from "react-router-dom";

function SectionOne({mainSwiper, setMainSwiper, thumbsSwiper}) { // swiper를 props로 받습니다.

    const location = useLocation();
    const {category_number} = location.state || {};  // useLocation으로 상태 가져오기
    const [isReady, setIsReady] = useState(false);
    const [initialSlideIndex, setInitialSlideIndex] = useState(0); // 초기 슬라이드 인덱스

    useEffect(() => {
        if (thumbsSwiper) {
            setIsReady(true);  // thumbsSwiper가 초기화된 후에 Swiper 렌더링 준비 완료
        }

        if (Array.isArray(category_number)) {
            // category_number 배열의 첫 번째 요소를 숫자로 변환하여 사용
            setInitialSlideIndex(parseInt(category_number[1], 10) - 1);
        } else {
            setInitialSlideIndex(parseInt(category_number, 10) - 1);  // category_number가 단일 값이면 숫자로 변환
        }

    }, [thumbsSwiper, location.pathname, category_number]);
    return (
        <article className="section01">
            {isReady && (
                <Swiper
                    onSwiper={(swiperInstance) => {
                        if (!mainSwiper) {
                            setMainSwiper(swiperInstance);
                        }
                    }}
                    slidesPerView={1}
                    effect={'cards'}
                    perSlideRotate={10}
                    grabCursor={true}
                    modules={[EffectCards, Thumbs]} // EffectCards 모듈을 첫 번째 Swiper에만 적용
                    thumbs={{swiper: thumbsSwiper}}
                    className="header-swiper"
                    initialSlide={(initialSlideIndex) || 0}
                >
                    <SwiperSlide>
                        <img src="/image/detail/ksquare/card_01.png" alt="Slide 1"/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/image/detail/studio/img_03.png" alt="Slide 3"/>
                    </SwiperSlide>
                </Swiper>
            )}
        </article>
    );
}

export default SectionOne;
