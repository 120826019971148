import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import Main from "./main";


function Index({changeFilter, fixedHeaderWidth}) {

    return (
        <React.Fragment>
            <Routes>
                <Route path="/" element={<Main changeFilter={changeFilter} fixedHeaderWidth={fixedHeaderWidth}/>}/>
            </Routes>
        </React.Fragment>
    );
}

export default Index;
