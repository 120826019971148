import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import Detail from "./sub1";


function Index({fixedHeaderWidth}) {
    return (
        <React.Fragment>
            <Routes>
                <Route path="/*" element={
                    <Detail/>
                }/>
            </Routes>
        </React.Fragment>
    );
}

export default Index;
