import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import Main from './view/pages/main';
import Detail from './view/pages/sub1';
import 'view/style/scss/resize.scss';
import 'view/style/scss/common.scss';
import 'react-toastify/dist/ReactToastify.css';
import Header from "view/parts/header";
import Footer from "view/parts/footer";
import FixedHeader from "view/parts/fixedHeader";
import SubHeader from "view/parts/subHeader";

function App() {
    const location = useLocation();
    const [isArea, setArea] = useState("/");
    const [filter, setFilter] = useState('*');
    const [fixedHeaderWidth, setFixedHeaderWidth] = useState(0);
    const [isMobile, setIsMobile] = useState(false); // 모바일 여부를 상태로 관리

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter); // 필터링 조건을 업데이트
    };

    useEffect(() => {
        setArea(location.pathname);
    }, [location]);

    useEffect(() => {
        // 윈도우 크기 변경을 감지하여 모바일 여부 판단
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1025);
        };

        handleResize(); // 초기 실행
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <section>
                {isMobile ? (
                    location.pathname === "/" ? (
                        <Header/> // 모바일에서 "/" 경로일 때 Header 표시
                    ) : (
                        <SubHeader/> // 모바일에서 "/" 이외의 경로일 때 SubHeader 표시
                    )
                ) : (
                    <Header/> // PC에서는 경로에 상관없이 항상 Header 표시
                )}

                <article className={"article-main"}>
                    {isArea === "/" && ( // 모바일이 아니고 "/" 경로일 때만 FixedHeader 표시
                        <FixedHeader
                            filter={filter}
                            onFilter={handleFilterChange}
                            setFixedHeaderWidth={setFixedHeaderWidth}
                            fixedHeaderWidth={fixedHeaderWidth}
                        />
                    )}

                    <Routes>
                        <Route path="/*" element={<Main
                            changeFilter={filter} // filter 상태를 Main 컴포넌트에 전달
                            setChangeFilter={setFilter} // setFilter 함수도 전달
                            setFixedHeaderWidth={setFixedHeaderWidth}
                            fixedHeaderWidth={fixedHeaderWidth}
                        />}/>
                        <Route path="/detail/*" element={<Detail/>}/>
                    </Routes>

                </article>
                <Footer/>

            </section>

        </React.Fragment>
    );
}

export default App;
