import React, {useState, useRef, useEffect} from "react";
import {useLocation} from "react-router-dom";
import "view/style/scss/sub.scss";
import {motion} from "framer-motion";
import SectionOne from "view/parts/swiper/koyo/header";
import SectionTwo from "view/parts/swiper/koyo/body";
import StudioOne from "view/parts/swiper/studio/header";
import StudioTwo from "view/parts/swiper/studio/body";
import EntertainmentOne from "view/parts/swiper/entertainment/header";
import EntertainmentTwo from "view/parts/swiper/entertainment/body";
import KenvinOne from "view/parts/swiper/kevinplace/header";
import KenvinTwo from "view/parts/swiper/kevinplace/body";
import KsquareOne from "view/parts/swiper/ksquare/header";
import KsquareTwo from "view/parts/swiper/ksquare/body";
import NewsOne from "view/parts/swiper/news/header";
import NewsTwo from "view/parts/swiper/news/body";
import Why from "./test";
import classNames from "classnames";

function Detail() {
    const location = useLocation();
    const {swiper} = location.state || {}; // location.state에서 swiper 값을 가져옴
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [mainSwiper, setMainSwiper] = useState(null);
    const [fixedHeaderWidth, setFixedHeaderWidth] = useState(0);
    const [isPC, setIsPC] = useState(true);

    const fixedHeaderRef = useRef(null);

    const [detail, setDetail] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        const path = location.pathname;
        const newPath = path.replace("/detail/", ""); // "/detail/" 부분을 제거
        setDetail(newPath);
    }, [location]);

    useEffect(() => {
        const updateDimensions = () => {
            if (fixedHeaderRef.current) {
                setFixedHeaderWidth(fixedHeaderRef.current.offsetWidth);
            }
            setIsPC(window.innerWidth >= 1024); // 1024px 이상이면 PC로 간주
        };

        // 초기 실행 시 크기 설정
        updateDimensions();

        // 윈도우 크기 변경 시 크기 업데이트
        window.addEventListener("resize", updateDimensions);

        // 클린업 함수에서 이벤트 리스너 제거
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);


    return (
        <React.Fragment>
            <div className={"sub-detail"}>
                <div className={"sub-fixed"} ref={fixedHeaderRef}>
                    {detail === "koyo" && (
                        <SectionOne
                            mainSwiper={mainSwiper}
                            setMainSwiper={setMainSwiper}
                            thumbsSwiper={thumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "studio" && (
                        <StudioOne
                            mainSwiper={mainSwiper}
                            setMainSwiper={setMainSwiper}
                            thumbsSwiper={thumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "entertainment" && (
                        <EntertainmentOne
                            mainSwiper={mainSwiper}
                            setMainSwiper={setMainSwiper}
                            thumbsSwiper={thumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "kevinplace" && (
                        <KenvinOne
                            mainSwiper={mainSwiper}
                            setMainSwiper={setMainSwiper}
                            thumbsSwiper={thumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "ksquare" && (
                        <KsquareOne
                            mainSwiper={mainSwiper}
                            setMainSwiper={setMainSwiper}
                            thumbsSwiper={thumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "news" && (
                        <NewsOne
                            mainSwiper={mainSwiper}
                            setMainSwiper={setMainSwiper}
                            thumbsSwiper={thumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}

                    <ul className="FixedHeader">
                        <li
                            className={classNames(
                                {add: detail === "koyo"}
                            )}
                        ><a href="/detail/koyo">koyo</a>
                        </li>
                        <li
                            className={classNames(
                                {add: detail === "studio"}
                            )}
                        ><a href="/detail/studio">studio</a>
                        </li>
                        <li
                            className={classNames(
                                {add: detail === "entertainment"}
                            )}
                        ><a href="/detail/entertainment">entertainment</a>
                        </li>
                        <li
                            className={classNames(
                                {add: detail === "kevinplace"}
                            )}
                        ><a href="/detail/kevinplace">kevin place</a>
                        </li>
                        <li
                            className={classNames(
                                {add: detail === "ksquare"}
                            )}
                        ><a href="/detail/ksquare">k square</a>
                        </li>
                        <li
                            className={classNames(
                                {add: detail === "news"}
                            )}
                        ><a href="/detail/news">news</a>
                        </li>
                    </ul>
                </div>

                <motion.div
                    className="content-inside detail"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.5}}
                    style={isPC ? {
                        marginLeft: `calc(${fixedHeaderWidth}px + 40px)`,
                        width: `calc(100% - ${fixedHeaderWidth}px - 40px)`
                    } : {}}
                >
                    {detail === "koyo" && (
                        <SectionTwo
                            thumbsSwiper={thumbsSwiper}
                            setThumbsSwiper={setThumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "studio" && (
                        <StudioTwo
                            thumbsSwiper={thumbsSwiper}
                            setThumbsSwiper={setThumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "entertainment" && (
                        <EntertainmentTwo
                            thumbsSwiper={thumbsSwiper}
                            setThumbsSwiper={setThumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "kevinplace" && (
                        <KenvinTwo
                            thumbsSwiper={thumbsSwiper}
                            setThumbsSwiper={setThumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "ksquare" && (
                        <KsquareTwo
                            thumbsSwiper={thumbsSwiper}
                            setThumbsSwiper={setThumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "news" && (
                        <NewsTwo
                            thumbsSwiper={thumbsSwiper}
                            setThumbsSwiper={setThumbsSwiper}
                            swiper={swiper}  // 전달된 state를 컴포넌트로 전달
                        />
                    )}
                    {detail === "test" && (
                        <>
                            <Why/>
                        </>
                    )}
                </motion.div>
            </div>
        </React.Fragment>
    );
}

export default Detail;
