import React, {useEffect, useState} from "react";
import "view/style/scss/main.scss";
import * as Md from "react-icons/md";
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";

function SubHeader({thumbsSwiper, setThumbsSwiper}) {
    const [isClick, setIsClick] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;
    const newPath = path.replace("/detail/", ""); // "/detail/" 부분을 제거
    const [detail, setDetail] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);

        const path = location.pathname;
        setDetail(newPath);
    }, [location]);

    const goBack = () => {
        navigate('/', {state: {category: newPath}}); // 이전 페이지로 이동
    };

    return (
        <React.Fragment>
            <div className={"mobile"}>
                <header
                    className={classNames("header",
                        {add: isClick === true}
                    )}
                >
                    <Md.MdArrowBackIosNew onClick={goBack}/>

                    <div className={classNames("hamburger",
                        {add: isClick === true}
                    )}
                         onClick={() => {
                             setIsClick(!isClick)
                         }}
                    >
                        <span></span>
                        <span></span>
                    </div>
                </header>

                <ul
                    className={classNames("FixedHeader",
                        {on: isClick === true}
                    )}
                >
                    <li
                        className={classNames(
                            {add: detail === "koyo"}
                        )}
                    ><a href="/detail/koyo">
                        <div className={"text"}>koyo</div>
                        <div className={"before"}>
                            <div>koyo</div>
                        </div>
                        <hr/>
                        <div className={"after"}>
                            <div>koyo</div>
                        </div>
                    </a>
                    </li>
                    <li
                        className={classNames(
                            {add: detail === "studio"}
                        )}
                    ><a href="/detail/studio">
                        <div className={"text"}>studio</div>
                        <div className={"before"}>
                            <div>studio</div>
                        </div>
                        <hr/>
                        <div className={"after"}>
                            <div>studio</div>
                        </div>
                    </a>
                    </li>
                    <li
                        className={classNames(
                            {add: detail === "entertainment"}
                        )}
                    ><a href="/detail/entertainment">
                        <div className={"text"}>entertainment</div>
                        <div className={"before"}>
                            <div>entertainment</div>
                        </div>
                        <hr/>
                        <div className={"after"}>
                            <div>entertainment</div>
                        </div>
                    </a>
                    </li>
                    <li
                        className={classNames(
                            {add: detail === "kevinplace"}
                        )}
                    ><a href="/detail/kevinplace">
                        <div className={"text"}>kevin place</div>
                        <div className={"before"}>
                            <div>kevin place</div>
                        </div>
                        <hr/>
                        <div className={"after"}>
                            <div>kevin place</div>
                        </div>
                    </a>
                    </li>
                    <li
                        className={classNames(
                            {add: detail === "ksquare"}
                        )}
                    ><a href="/detail/ksquare">
                        <div className={"text"}>k square</div>
                        <div className={"before"}>
                            <div>k square</div>
                        </div>
                        <hr/>
                        <div className={"after"}>
                            <div>k square</div>
                        </div>
                    </a>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

export default SubHeader;
