import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import {Thumbs, Navigation} from 'swiper/modules';
import * as Io from "react-icons/io";
import {Link, useLocation} from "react-router-dom";
import * as Lu from "react-icons/lu";

function SectionTwo({thumbsSwiper, setThumbsSwiper}) {
    const location = useLocation();
    const {category_number} = location.state || {};
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        if (swiperInstance && category_number !== undefined) {
            swiperInstance.slideTo((category_number - 1) || 0, 0);
        }
    }, [swiperInstance, category_number]);

    const handleImageLoad = (event) => {
        const {width} = event.target;
        setImageWidth(width);
    };

    return (
        <article className="section02">
            <Swiper
                onSwiper={(swiper) => {
                    if (!thumbsSwiper) {
                        setThumbsSwiper(swiper);
                    }
                    setSwiperInstance(swiper);
                }}
                autoHeight={true}
                onSlideChange={() => {
                    window.scrollTo(0, 0);
                }}
                slidesPerView={1}
                modules={[Thumbs]}
                className="fixed-card"
                allowTouchMove={false}
                simulateTouch={false}
                draggable={false}
            >
                <SwiperSlide>
                    <div className={"card-wrap"}>
                        <img src={"/image/detail/koyo/contents_img01.png"}/>

                        <div className={"text"}>
                            한국의 <span className={"bold"}>(주)KOYO</span>는 <span className={"bold"}>엔터테인먼트, 라이브 커머스, 에이전시,<br/>
                                빈티지 사업, 교육 사업</span>까지의 다양한 사업을 운영하고 있습니다.
                        </div>

                        <img src={"/image/detail/koyo/contents_img02.png"}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"card-wrap"}>
                        <div className={"card-background"}>
                            <div className={"title-line pc"}>
                                <div className={"top"}>
                                    <div>ARTISTS</div>
                                    <div>
                                        (주) KOYO와 함께 한류를 개척하는 Live commerce에<br/>
                                        특화된 아티스트 / 쇼퍼테이너들을 소개합니다.
                                    </div>
                                </div>
                                <div className={"bottom"}>
                                    <div>
                                        SHOPPERTAINER
                                    </div>
                                </div>
                            </div>
                            <div className={"title-line m"}>
                                <div className={"top"}>
                                    <div>ARTISTS</div>
                                    <div className={"bold"}>
                                        SHOPPERTAINER
                                    </div>
                                    <div>
                                        (주) KOYO와 함께 한류를 개척하는 Live commerce에<br/>
                                        특화된 아티스트 / 쇼퍼테이너들을 소개합니다.
                                    </div>
                                </div>

                            </div>
                            <div className={"body-swiper"}>
                                <Swiper
                                    className={"_body-swiper"}
                                    spaceBetween={30}
                                    slidesPerView={4}
                                    loop={true}
                                    modules={[Navigation]}
                                    autoHeight={true}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                    breakpoints={{
                                        1300: {
                                            slidesPerView: 4,
                                        },
                                        1025: {
                                            slidesPerView: 2,
                                        },
                                        0: {
                                            slidesPerView: 1,
                                        }
                                    }}
                                >
                                    <SwiperSlide onLoad={handleImageLoad}>
                                        <img src={"/image/detail/koyo/people_01.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_02.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border yellow"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                        <div>
                                                            <span>Main concept</span>
                                                            밈 천재, 도파민 중독된 친한 친구 같은 언니
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={"/image/detail/koyo/people_02.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_01.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border green"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            오버핏, 스포티 의류
                                                        </div>
                                                        <div>
                                                            <span>Main concept</span>
                                                            능글맞은 아들, 동생의 옷 추천
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={"/image/detail/koyo/people_03.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_03.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border blue"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                        <div>
                                                            <span>Main concept</span>
                                                            로드샵 4년, 교회 오빠의 옷 추천 맛집
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={"/image/detail/koyo/people_04.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_04.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border pink"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide onLoad={handleImageLoad}>
                                        <img src={"/image/detail/koyo/people_01.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_02.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border yellow"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                        <div>
                                                            <span>Main concept</span>
                                                            스타일리스트 출신, 상황별 스타일 코디 추천
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={"/image/detail/koyo/people_02.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_01.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border green"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={"/image/detail/koyo/people_03.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_03.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border blue"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={"/image/detail/koyo/people_04.png"} className={"main-image"}/>
                                        <div className={"active-on"}>
                                            <img src={"/image/detail/koyo/people_line_04.png"}
                                                 className={"animation-img"}/>
                                            <div className={"border pink"}>
                                                <hr/>
                                                <div className={"border-ab"}>
                                                    <div className={"textLine"}>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                        <div>
                                                            <span>Main product</span>
                                                            MZ세대 타겟의 트렌디한 스타일 의류
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <div className="swiper-button-prev">
                                        <Io.IoIosArrowBack/>
                                    </div>
                                    <div
                                        className="swiper-button-next"
                                        style={{transform: `translate(calc(${imageWidth} * 2)px, -50%)`}}
                                    >
                                        <Io.IoIosArrowForward/>
                                    </div>
                                </Swiper>
                            </div>
                            <div className={"bottom-line"}>
                                <div className={"top"}>
                                    <div>트렌디한 한류를 위한 꿈을 KOYO와 함께하세요!</div>
                                </div>
                                {/*<div className={"bottom"}>*/}
                                {/*    <button type={"button"}>*/}
                                {/*        <div>*/}
                                {/*            <img src={"/image/detail/koyo/button.svg"}/>*/}
                                {/*        </div>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            BRANDING
                        </div>

                        <div className={"new-title"}>
                            고요컴퍼니, 틱톡 라이브커머스의 셀러 브랜딩 솔루션 제공
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_01.png"/>

                        <div className={"new-content"}>
                            국내 라이브커머스 시장은 포화상태다. 수많은 라이브플랫폼이 존재하는 가운데, 쇼호스트(셀러) 및 상품을 제공하는 기업에서는 어느 플랫폼에 라이브를 진행해야 할지
                            고민이 될
                            수밖에 없다. 플랫폼을 선택하기에 앞서 해당 플랫폼의 시청자, 이용자의 객관적인 정보와 수요를 확인해야 한다.<br/><br/>현재 라이브커머스 시장에는 그립,
                            네이버쇼핑라이브 등에서
                            활동하던 쇼호스트(셀러)와 기업들이 대거 틱톡으로 유입되고 있다. 이들은 틱톡의 글로벌 판매 가능성에 주목하며 새로운 기회를 모색하고 국내를 넘어 세계 시장으로의
                            영향력
                            확장을 노리고 있다.<br/><br/>이에 소셜 미디어 플랫폼인 틱톡에서 라이브커머스를 진행하고 있는 고요컴퍼니 측은 다양한 셀러 매니지먼트 와 자체상품 개발에
                            초점을 둔
                            브랜딩 솔루션을
                            제안한다고 밝혔다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 더퍼스트미디어<br/>2024.02.06 14:20 by 김대일 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.thefirstmedia.net/news/articleView.html?idxno=138212"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            VOLUNTEER ACTIVITY
                        </div>

                        <div className={"new-title"}>
                            고요 회사, 태국 K-스튜디오 코퍼레이션 설립 및 글로벌 나눔 프로젝트 실시
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_03.png"/>

                        <div className={"new-content"}>
                            고요 회사가 태국에서 K-스튜디오를 설립하며 첫 번째 글로벌 나눔 프로젝트를 시작했다고 밝혔다.<br/><br/>
                            이는 한국 브랜드의 의류, 화장품, 그리고 밀키트
                            제품들이 동남아
                            시장에 효율적으로 진입할 수 있도록 지원하는 온라인 라이브 팝업 서비스다.<br/><br/>
                            더불어 첫 번째 글로벌 나눔 프로젝트를 실시했다. 태국의 가장 큰 명절인 송끄란은
                            한국의
                            설날과 유사하며 가족들이 모이는 즐거운 날로 고요 회사의 임원들과 현지 K-스튜디오 직원들은 송끄란을 즐길 수 없는 사람들과 함께 봉사 활동에
                            참여했다.<br/><br/>
                            코로나바이러스로 인한 관광업의 어려움을 겪고 있는 태국은 거리와 관광지에 노숙자 수가 증가했고 이에 고요 회사는 첫 봉사 활동으로 300명을 위한 도시락을 준비하고
                            배포했다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 더퍼스트미디어<br/>2024.02.06 14:20 by 김대일 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.globalepic.co.kr/view.php?ud=202404191105188356cf2d78c68_29"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </article>
    );
}

export default SectionTwo;
