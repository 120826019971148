import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/thumbs';
import {EffectCards, Thumbs} from 'swiper/modules';
import {useLocation} from "react-router-dom";

function SectionOne({mainSwiper, setMainSwiper, thumbsSwiper}) { // swiper를 props로 받습니다.

    const location = useLocation();
    const {category_number} = location.state || {};  // useLocation으로 상태 가져오기
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (thumbsSwiper) {
            setIsReady(true);  // thumbsSwiper가 초기화된 후에 Swiper 렌더링 준비 완료
        }

        console.log(category_number);
    }, [thumbsSwiper]);

    return (
        <article className="section01">
            {isReady && (
                <Swiper
                    onSwiper={(swiperInstance) => {
                        if (!mainSwiper) {
                            setMainSwiper(swiperInstance);
                        }
                    }}
                    slidesPerView={1}
                    effect={'cards'}
                    perSlideRotate={10}
                    grabCursor={true}
                    modules={[EffectCards, Thumbs]} // EffectCards 모듈을 첫 번째 Swiper에만 적용
                    thumbs={{swiper: thumbsSwiper}}
                    className="header-swiper"
                    initialSlide={(category_number - 1) || 0}
                >
                    <SwiperSlide>
                        <img src="/image/detail/kevinplace/img_01.png" alt="Slide 1"/>
                    </SwiperSlide>
                </Swiper>
            )}
        </article>
    );
}

export default SectionOne;
