import React, {useEffect, useRef, useState} from 'react';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import {items} from 'api/card';
import 'view/style/scss/main.scss';
import {useLocation, useNavigate} from "react-router-dom";

function Main({changeFilter, fixedHeaderWidth}) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLink, setLink] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);

        // 새로고침 시 location.state 초기화
        if (location.state) {
            window.history.replaceState({}, document.title);
        }

    }, [location]);

    const gridRef = useRef(null);
    const isoRef = useRef(null);

    useEffect(() => {
        isoRef.current = new Isotope(gridRef.current, {
            itemSelector: '.grid-item',
            layoutMode: 'masonry',
            transitionDuration: '0.8s',
            stagger: 30,
            percentPosition: true,
            masonry: {
                columnWidth: '.grid-sizer',
                gutter: 40,
            },
            getSortData: {
                id: '[data-id] parseInt'  // ID 값을 기준으로 정렬, 정수형으로 변환
            },
            sortBy: 'id',  // ID 순으로 정렬
            sortAscending: true  // 오름차순 정렬
        });

        imagesLoaded(gridRef.current, () => {
            isoRef.current.layout();
        });

        return () => {
            if (isoRef.current) {
                isoRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (!isoRef.current) return;
        isoRef.current.arrange({filter: changeFilter});

        // changeFilter 값에서 '.'을 제거하고 isLink에 저장
        const linkValue = changeFilter.replace('.', '');
        setLink(linkValue);
    }, [changeFilter]);

    const handleClick = (item) => {
        if (item.link.includes('yes')) {
            let targetCategory = item.category[0];

            if (changeFilter === '*') {
                navigate(`detail/${targetCategory}`, {state: {category_number: item.swiper}});
            } else {
                const cleanedFilter = changeFilter.replace(/\./g, ''); // .을 제거
                navigate(`detail/${cleanedFilter}`, {state: {category_number: item.swiper}});
            }
        } else {
            // 링크가 "no"인 경우에는 이동하지 않음
            console.log('This item cannot be clicked:', item);
        }
    };

    return (
        <div className="content-inside main"
             style={{
                 marginLeft: `calc(${fixedHeaderWidth}px + 40px)`,
                 width: `calc(100% - ${fixedHeaderWidth}px - 40px)`
             }}
        >
            <div className="grid" ref={gridRef}>
                <div className="grid-sizer"></div>
                {items.map(item => (
                    <div
                        key={item.id}
                        className={`grid-item ${Array.isArray(item.category) ? item.category.join(' ') : item.category}`}
                        data-id={item.id}  // ID 값을 data-id 속성으로 추가
                        onClick={() => handleClick(item)}
                        style={{cursor: item.link.includes('yes') ? 'pointer' : 'default'}}
                    >
                        <img src={item.image} alt={`Item ${item.id}`} className="masonry-image"/>
                        {item.text && (
                            <div className="item-text">
                                <div className={"item-text-bottom"}>
                                    {item.text.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Main;
