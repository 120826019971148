import React, {useEffect, useRef, useState} from "react";
import "view/style/scss/main.scss";
import classNames from "classnames";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import * as Md from "react-icons/md";

function FixedHeader({filter, onFilter, setFixedHeaderWidth, fixedHeaderWidth}) {
    const [isPC, setIsPC] = useState(true);
    const fixedHeaderRef = useRef(null);

    const handleClick = (value) => {
        onFilter(value);  // 필터 값을 상위 컴포넌트(App)로 전달
        window.scrollTo(0, 0);
    };

    const filterItems = [
        {label: 'ALL', value: '*'},
        {label: 'koyo', value: '.koyo'},
        {label: 'studio', value: '.studio'},
        {label: 'entertainment', value: '.entertainment'},
        {label: 'kevin place', value: '.kevinplace'},
        {label: 'k square', value: '.ksquare'},
        {label: 'NEWS', value: '.news'},
    ];

    useEffect(() => {
        if (fixedHeaderRef.current) {
            setFixedHeaderWidth(fixedHeaderRef.current.offsetWidth);
        }

        // 윈도우의 크기를 감지하여 PC인지 여부를 확인
        const handleResize = () => {
            setIsPC(window.innerWidth >= 1024); // 예를 들어, 1024px 이상이면 PC로 간주
        };

        handleResize(); // 초기 실행
        window.addEventListener("resize", handleResize);

        // 클린업 함수에서 이벤트 리스너 제거
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setFixedHeaderWidth]);

    const swiperRef = useRef(null); // Swiper 인스턴스를 참조하기 위한 ref 생성

    const slidePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev(); // 이전 슬라이드로 이동
        }
    };

    const slideNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext(); // 다음 슬라이드로 이동
        }
    };

    return (
        <React.Fragment>
            {isPC ? (
                <ul className="FixedHeader" ref={fixedHeaderRef}>
                    {filterItems.map((item) => (
                        <li key={item.value}
                            className={classNames({add: filter === item.value})}
                            onClick={() => handleClick(item.value)}
                        >
                            {item.label}
                        </li>
                    ))}
                </ul>
            ) : (
                <div className={"mobile-fixed"}>
                    <Md.MdOutlineArrowLeft onClick={slidePrev}/> {/* 왼쪽 화살표 클릭 시 이전 슬라이드로 이동 */}
                    <Swiper
                        spaceBetween={10}
                        className="FixedHeader"
                        slidesPerView={'auto'}
                        ref={swiperRef} // Swiper 인스턴스를 참조
                    >
                        {filterItems.map((item) => (
                            <SwiperSlide key={item.value} className="swiper-slide">
                                <li className={classNames({add: filter === item.value})}
                                    onClick={() => handleClick(item.value)}
                                >
                                    {item.label}
                                </li>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Md.MdOutlineArrowRight onClick={slideNext}/> {/* 오른쪽 화살표 클릭 시 다음 슬라이드로 이동 */}
                </div>
            )}
        </React.Fragment>
    );
}

export default FixedHeader;
