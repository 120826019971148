import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query'; // react-query 임포트
import App from './app';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* common css S */
import 'view/style/scss/resize.scss';
import 'view/style/scss/common.scss';
/* common css E */

const root = ReactDOM.createRoot(document.getElementById('root'));

// QueryClient 인스턴스 생성
const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}> {/* QueryClientProvider 추가 */}
            <BrowserRouter>
                <App/>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
);
