import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide,} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import {Thumbs, Navigation} from 'swiper/modules';
import * as Io from "react-icons/io";
import {Link, useLocation} from "react-router-dom";
import * as Lu from "react-icons/lu";

function SectionTwo({thumbsSwiper, setThumbsSwiper}) {
    const location = useLocation();
    const {category_number} = location.state || {};  // useLocation으로 상태 가져오기
    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        if (swiperInstance && category_number !== undefined) {
            // 애니메이션 없이 초기 슬라이드로 이동
            swiperInstance.slideTo((category_number - 1) || 0, 0);
        }
    }, [swiperInstance, category_number]);
    return (

        <article className="section02">
            <Swiper
                onSwiper={(swiper) => {
                    if (!thumbsSwiper) {
                        setThumbsSwiper(swiper);
                    }
                }}
                autoHeight={true}
                onSlideChange={() => {
                    window.scrollTo(0, 0); // 슬라이드가 변경될 때마다 페이지 상단으로 스크롤
                }}

                slidesPerView={1} // thumbsSwiper로 썸네일을 표시할 때 일반적인 슬라이드 효과 사용
                modules={[Thumbs]} // Thumbs 모듈만 적용
                className="fixed-card"

                allowTouchMove={false} // 터치로 슬라이드 이동을 비활성화
                simulateTouch={false} // 터치 시뮬레이션 비활성화
                draggable={false} // 마우스로 드래그를 통한 슬라이드 이동 비활성화
            >
                <SwiperSlide>
                    <div className={"studio-content entertainment"}>
                        <div className={"entertainment-title"}>
                            <img src={"/image/detail/entertainment/image_01.png"}/>
                        </div>

                        <div className={"entertainment-title-line"}>
                            <div className={"_text"}>
                                교육원 제휴
                            </div>
                            <hr/>

                            <div className={"_text"}>
                                취업 보장
                            </div>
                        </div>

                        <div className={"entertainment-picture"}>
                            <div className={"_picture-left"}>
                                <div className={"_picture-title"}>
                                    전문 쇼퍼테이너 육성
                                </div>
                                <div className={"_picture-content"}>

                                    교육원과의 제휴와 전문 인력을 통한 체계적인 교육으로<br/>
                                    교육생들의 취업을 보장하고, 우리의 인적 자산이 되는<br/>
                                    <span>전문 쇼퍼테이너</span>를 <span>KOYO ACADEMY</span>에서 자체적으로<br/>
                                    육성합니다.

                                </div>
                                <div className={"_picture-img"}>
                                    <img src={"/image/detail/entertainment/image_02.png"}/>
                                </div>
                            </div>
                            <div className={"_picture-right"}>
                                <img src={"/image/detail/entertainment/image_03.png"}/>
                            </div>
                        </div>


                        <div className={"entertainment-picture logo"}>
                            <div className={"_picture-left"}>

                                <div className={"_picture-content"}>

                                    <div className={"_picture-logo"}>
                                        <img src={"/image/detail/entertainment/image_logo.png"}/>
                                        과의 협력으로 교육원을 통한<br/>
                                        한국 - 태국에서의 활동 지원과 ISO 자격증 발급
                                    </div>
                                </div>

                            </div>
                            <div className={"_picture-right"}>
                                <img src={"/image/detail/entertainment/image_04.png"}/>
                            </div>
                        </div>

                        <div className={"entertainment-table"}>
                            <div>
                                <div className={"_table-title"}><span>‘ISO 인플루언서 국제 자격증’</span>으로</div>
                                <div className={"_table-content"}>전문성 인증</div>
                                <div className={"_table-content"}>콘텐츠 품질 향상</div>
                                <div className={"_table-content"}>브랜드 협업 기회 확대</div>
                                <div className={"_table-content"}>시장에서의 신뢰도 향상</div>
                                <div className={"_table-content"}>법정 규정 준수</div>
                            </div>
                            <div>
                                <div className={"_table-title"}>교육 수료 및 <span>국제 자격증 발급</span> 혜택</div>
                                <div className={"_table-content"}>실시간 교육 시스템</div>
                                <div className={"_table-content"}>질 높은 교육 (이주선 원장)</div>
                                <div className={"_table-content"}>방송 출연 기회</div>
                                <div className={"_table-content"}>취업 연계 시스템</div>
                                <div className={"_table-content"}>한국 비자 취득 기회 제공</div>
                            </div>
                        </div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            ENTERTAINMENT ACTIVITIES
                        </div>

                        <div className={"new-title"}>
                            리사(RATTIKAN WAIPRIB) 고요컴퍼니 전속 계약 체결
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_02.png"/>

                        <div className={"new-content"}>
                            고요컴퍼니가 리사(RATTIKAN WAIPRIB)와 전속 계약을 체결했다.<br/><br/>
                            고요컴퍼니는 자사에서 전개하는 여성의류 브랜드에 리사를 모티브로 한 브랜드를 런칭하고,
                            모델로 발탁했다고 29일 밝혔다.<br/><br/>

                            브랜드 ‘고요 by 리사'는 아티스트의 스타일과 의견을 적극 반영하여 리사가 평소에 즐겨 입는 크롭티와, 프리사이즈 티로
                            출시하게 되었다. 평소 뛰어난 패션 감각과 스타일링을 보여준 리사의 이미지가 브랜드모델 선정으로 이어졌다.<br/><br/>

                            회사 관계자는 “고요컴퍼니는 의류 브랜드
                            외에도
                            향후 뷰티,
                            화장품, 라이프 스타일 제품을 아티스트 중심적으로 소통하고 제작하여, 다양한 분야로의 확장을 준비하고 있다”고 전했다.<br/><br/>또한 소속아티스트 ‘리사’는
                            향후 진행될
                            차기작 역시 긍정적으로 검토 중이며, 각종 드라마 촬영, 방송 촬영 등 다방면에서의 활발한 활동을 예고했다. 한편 리사는 가제-고요클로젯 웹드라마의 출연을 확정
                            짓고, 오는 8월 01일에 첫 촬영에 들어간다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : GLOBALEPIC<br/>2024-04-19 16:00 이수환 글로벌에픽 기자 lsh@globalepic.co.kr
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"http://www.kdpress.co.kr/news/articleView.html?idxno=120879"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            AGENCY
                        </div>

                        <div className={"new-title"}>
                            고요컴퍼니, 순이엔티와 틱톡 에이전시 계약 체결... 글로벌 시장 진출 가속화
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_06.png"/>

                        <div className={"new-content"}>
                            고요컴퍼니가 종합 MCN회사인 순이엔티와 손을 잡고 글로벌 틱톡 라이브커머스 시장을 공략한다고 발표했다.<br/><br/>
                            두 회사는 틱톡을 중심으로 한 라이브커머스와 엔터테인먼트 분야의 협력을 강화하고, 이를 통해 글로벌 시장 진출을 가속화하고자 한다고 밝혔다.
                            고요컴퍼니는 엔터테인먼트와 라이브커머스의 융합을 통해 소비자에게 새로운 경험을 제공하고, 글로벌 시장을 선도하는 종합 컨텐츠 기업을 지향한다.<br/><br/>이번
                            협력을
                            통해 고요컴퍼니 측은 태국 진출 추진 가속화를 기대하고 있으며, 디지털 미디어와 엔터테인먼트 분야에서의 경쟁력을 한층 끌어올릴 것으로 전망하고
                            있다.<br/><br/><br/>특히,
                            두 분야의 협력 강화를 통해 소비자들에게 보다 다채로운 콘텐츠를 제공할 수 있을 것으로 기대된다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : CBC뉴스 | CBCNEWS(https://www.cbci.co.kr)<br/>2024.06.12 16:58 by 이종하 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.cbci.co.kr/news/articleView.html?idxno=469147#google_vignette"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            KOYO ACADEMY
                        </div>

                        <div className={"new-title"}>
                            고요컴퍼니의 태국 고요아카데미, 대표원장으로 이주선 단장 합류
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_04.png"/>

                        <div className={"new-content"}>
                            고요컴퍼니는 라이브커머스 및 엔터테인먼트 사업을 태국으로 확장하면서 국내 브랜드 의류, 화장품, 밀키트 등의 틱톡 라이브 팝업 사업을 진행하는 고요스튜디오와
                            인플루언서, 라이브커머스, 케이팝댄스, 한국어, 보컬 등 여러 교육을 배울 수 있는 고요아카데미 사업을 위한 법인을 설립하였다.
                            <br/><br/>
                            고요아카데미는 모든 교육과정의 끝에 국제표준 ISO자격증을 취득할 수 있는 전문 교육기관으로 자격증을 위한 교육뿐만 아니라 태국 현지 강사와 한국의 유명 강사진이
                            함께 실무에도 활용할 수 있는 수업을 진행한다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 국제뉴스<br/>2024.05.08 14:40 권찬민 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.gukjenews.com/news/articleView.html?idxno=2988737"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            ENTERTAINMENT
                        </div>

                        <div className={"new-title"}>
                            고요엔터테인먼트 이사 겸 인플루언서인 ‘비르’, 태국 글로벌 나눔 프로젝트 동참
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_05.png"/>

                        <div className={"new-content"}>
                            고요엔터테인먼트 이사 겸 유명 인플루언서인 ‘비르’는 최근 태국 법인 설립을 위해 출장 차 태국을 방문해 태국 현지 법인 K-Studio의 첫 번째 글로벌 나눔
                            프로젝트에 함께 참여헀다고 18일 밝혔다.
                            <br/><br/>
                            송크란은 태국의 최대 명절로 전 세계 사람들도 이 송크란을 즐기기 위해 태국을 방문하기도 한다. 그러나 이런 송크란을 가족도 없이 지내거나 배고픔을 견뎌야 하는
                            이들도 있어 ‘비르’도 함께 글로벌 나눔 프로젝트에 동참하게 됐다고 설명했다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 비즈월드<br/>2024.04.18 17:04 정재훈 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.bizwnews.com/news/articleView.html?idxno=80990#google_vignette"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>


            </Swiper>
        </article>
    );
}

export default SectionTwo;
