import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/thumbs';
import {EffectCards, Thumbs, Pagination, Navigation} from 'swiper/modules';
import {useLocation} from "react-router-dom";
import * as Md from "react-icons/md";

function SectionOne({mainSwiper, setMainSwiper, thumbsSwiper}) {
    const location = useLocation();
    const {category_number} = location.state || {};  // useLocation으로 상태 가져오기
    const [isReady, setIsReady] = useState(false);
    const [initialSlideIndex, setInitialSlideIndex] = useState(0); // 초기 슬라이드 인덱스

    useEffect(() => {
        if (thumbsSwiper) {
            setIsReady(true);  // thumbsSwiper가 초기화된 후에 Swiper 렌더링 준비 완료
        }

        if (Array.isArray(category_number)) {
            // category_number 배열의 첫 번째 요소를 숫자로 변환하여 사용
            setInitialSlideIndex(parseInt(category_number[1], 10) - 1);
        } else {
            setInitialSlideIndex(parseInt(category_number, 10) - 1);  // category_number가 단일 값이면 숫자로 변환
        }

    }, [thumbsSwiper, location.pathname, category_number]);

    useEffect(() => {
        console.log("category_number", category_number);
        console.log("initialSlideIndex", initialSlideIndex);
    }, [initialSlideIndex]);  // initialSlideIndex가 업데이트될 때마다 로그를 출력

    return (
        <article className="section01">
            {isReady && (
                <>
                    <Swiper
                        onSwiper={(swiperInstance) => {
                            if (!mainSwiper) {
                                setMainSwiper(swiperInstance);
                            }
                        }}
                        initialSlide={(initialSlideIndex) || 0}
                        slidesPerView={1}
                        effect={'cards'}
                        grabCursor={true}
                        modules={[EffectCards, Thumbs, Pagination, Navigation]}
                        thumbs={{swiper: thumbsSwiper}}
                        className="header-swiper"
                        pagination={{
                            el: '.custom-pagination',
                            type: 'fraction',
                        }}
                        navigation={{
                            nextEl: '.custom-next',
                            prevEl: '.custom-prev',
                        }}
                    >
                        <SwiperSlide>
                            <img src="/image/detail/news/card_01.png" alt="Slide 1"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/image/detail/news/card_02.png" alt="Slide 2"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/image/detail/news/card_03.png" alt="Slide 3"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/image/detail/news/card_04.png" alt="Slide 4"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/image/detail/news/card_05.png" alt="Slide 5"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/image/detail/news/card_06.png" alt="Slide 6"/>
                        </SwiperSlide>
                    </Swiper>
                    <div className={"swiper-page"}>
                        <div className="custom-prev"><Md.MdArrowBackIosNew/></div>
                        <div className="custom-pagination"></div>
                        <div className="custom-next"><Md.MdArrowForwardIos/></div>
                    </div>
                </>
            )
            }
        </article>
    )
        ;
}

export default SectionOne;
