export const items = [
    {
        id: 1,
        category: ['koyo'],
        image: '/image/main/koyo_01.png',
        link: ['yes'],
        swiper: ['1']
    },
    {
        id: 2,
        category: ['koyo'],
        image: '/image/main/koyo_02.png',
        link: ['yes'],
        swiper: ['2']
    },
    // {
    //     id: 3,
    //     category: ['koyo', 'studio', 'entertainment', 'kevinplace'],
    //     image: '/image/main/koyo_03.png',
    //     link: ['no', 'no', 'no', 'no'],
    //     swiper: ['1'],
    //     text: "라이브 셀러에서 엔터테인먼트 스타까지,\n" +
    //         "무한한 가능성이 열리는 유쾌한 플랫폼인\n" +
    //         "‘틱톡’을 주 채널로 활발한 활동들을 영위합니다"
    // },
    // {
    //     id: 4,
    //     category: ['koyo', 'studio', 'ksquare'],
    //     image: '/image/main/koyo_05.png',
    //     link: ['no', 'no', 'no', 'no'],
    //     text: "엔터테인먼트와 커머스의 완벽한 조화,\n" +
    //         "판매자와 소비자를 잇는 가장 가까운 쇼핑인\n" +
    //         "라이브 커머스를 통해 높은 매출을 달성합니다."
    // },
    // {
    //     id: 5,
    //     category: ['koyo'],
    //     image: '/image/main/koyo_06.png',
    //     link: ['no'],
    //     text: "우리의 특별한 타겟층인 국내 거주 외국인들의\n" +
    //         "원활한 소비와 K 문화 활동을 지원하는\n" +
    //         "커뮤니티 플랫폼과 KOYO의 카드를 소개합니다."
    // },

    {
        id: 6,
        category: ['koyo', 'news'],
        image: '/image/main/koyo_04.png',
        link: ['yes'],
        swiper: ['3', '1']
    },
    {
        id: 7,
        category: ['entertainment', 'news'],
        image: '/image/main/koyo_09.png',
        link: ['yes'],
        swiper: ['2', '2']
    },
    {
        id: 8,
        category: ['koyo', 'news'],
        image: '/image/main/koyo_08.png',
        link: ['yes'],
        swiper: ['4', '3']
    },
    {
        id: 9,
        category: ['entertainment', 'news'],
        image: '/image/main/koyo_07.png',
        link: ['yes'],
        swiper: ['3', '6']
    },


    // {
    //     id: 10,
    //     category: ['kevinplace'],
    //     image: '/image/main/koyo_11.png',
    //     link: ['no'],
    //     text: "향수와 트렌드가 공존하는\n" +
    //         "지속 가능한 패션의 선두 아이템인 빈티지를\n" +
    //         "주력으로 판매합니다."
    // },
    {
        id: 11,
        category: ['entertainment', 'news'],
        image: '/image/main/koyo_12.png',
        link: ['yes'],
        swiper: ['4', '4']
    },
    {
        id: 12,
        category: ['kevinplace'],
        image: '/image/main/koyo_10.png',
        link: ['yes'],
        swiper: ['1']
    },
    {
        id: 13,
        category: ['studio'],
        image: '/image/main/koyo_14.png',
        link: ['yes'],
        swiper: ['1']
    },
    {
        id: 14,
        category: ['entertainment', 'news'],
        image: '/image/main/koyo_15.png',
        link: ['yes'],
        swiper: ['5', '5']
    },

    {
        id: 15,
        category: ['studio', 'ksquare'],
        image: '/image/main/koyo_13.png',
        link: ['yes'],
        swiper: ['3', '2']
    },
    {
        id: 16,
        category: ['studio'],
        image: '/image/main/koyo_18.png',
        link: ['yes'],
        swiper: ['2']
    },
    {
        id: 17,
        category: ['entertainment'],
        image: '/image/main/koyo_17.png',
        link: ['yes'],
        swiper: ['1']
    },

    {
        id: 18,
        category: ['ksquare'],
        image: '/image/main/koyo_16.png',
        link: ['yes'],
        swiper: ['1']
    },


];
