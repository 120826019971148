import React, {useEffect, useState, useCallback} from "react";
import "view/style/scss/footer.scss";
import classNames from "classnames";
import {Link, useLocation} from "react-router-dom";
import * as Fa from "react-icons/fa6";
import * as Si from "react-icons/si";

function Footer({...props}) {
    const [showButton, setShowButton] = useState(false);
    const [floatIconVisible, setFloatIconVisible] = useState(false);


    useEffect(() => {
        // 스크롤 이벤트 핸들러
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        // 문서 클릭 이벤트 핸들러
        const handleClickOutside = (event) => {
            if (!event.target.closest('._box_floating')) {
                setFloatIconVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('click', handleClickOutside);

        // 클린업
        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // 클릭 핸들러
    const handleBoxFloatingClick = useCallback((event) => {
        event.stopPropagation(); // 이벤트 전파를 막아야 클릭 외부 처리와 충돌하지 않음
        setFloatIconVisible(!floatIconVisible);
    }, [floatIconVisible]);

    const ScrollTop = useCallback((event) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    return (
        <React.Fragment>
            <footer>

                <div className="footer-bottom">
                    <div className="flexLine">
                        <div className="logo-address">
                            <div className="logo"><img src="/image/logo-color.svg"/></div>
                            <div className="address">
                                회사 : (주)고요컴퍼니<br/>
                                주소 : 경기 부천시 원미구 중동로 244 상록센트럴타워 505<br/>
                                사업자 등록번호 : 288-86-02937<br/>
                                대표자 : 김동현
                            </div>
                        </div>
                        <div className="callBox">
                            <img src={"/image/Contact.svg"}/>
                            <div className="call">
                                <div className="_call">Call :</div>
                                <div className="_phone">010. 5758. 5855</div>
                            </div>
                            <div className="maill">
                                <div className="_call">Mail :</div>
                                <div className="_phone">koyo_ceo@koyocompany.com</div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="footer-copy">
                    <div>
                        Copyright © 2024, koyo company. All rights reserved.
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
        ;
}

export default Footer;
