import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide,} from 'swiper/react';
import 'swiper/css';
import {Thumbs, Navigation, Pagination} from 'swiper/modules';
import * as Io from "react-icons/io";

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Link} from "react-router-dom";

function SectionTwo({thumbsSwiper, setThumbsSwiper}) {
    const [imageWidth, setImageWidth] = useState(0);  // width 값을 저장하는 상태

    const handleImageLoad = (event) => {
        const {width} = event.target;
        setImageWidth(width);  // width 값만 저장
    };

    return (

        <article className="section02">
            <Swiper
                onSwiper={(swiper) => {
                    if (!thumbsSwiper) {
                        setThumbsSwiper(swiper);
                    }
                }}

                autoHeight={true}
                onSlideChange={() => {
                    window.scrollTo(0, 0); // 슬라이드가 변경될 때마다 페이지 상단으로 스크롤
                }}

                slidesPerView={1} // thumbsSwiper로 썸네일을 표시할 때 일반적인 슬라이드 효과 사용
                modules={[Thumbs]} // Thumbs 모듈만 적용
                className="fixed-card"
                onInit={() => {
                    console.log('Thumbs Swiper initialized');
                }}
                allowTouchMove={false} // 터치로 슬라이드 이동을 비활성화
                simulateTouch={false} // 터치 시뮬레이션 비활성화
                draggable={false} // 마우스로 드래그를 통한 슬라이드 이동 비활성화
            >
                <SwiperSlide>
                    <div className={"studio-content ksquare"}
                         style={{backgroundImage: "url('/image/detail/ksquare/background.png')"}}>
                        <div className={"content-area "}>
                            <div className={"title"}>
                                변하고 싶은 모두를 위한<br/>
                            </div>

                            <img src={"/image/detail/ksquare/first_title.svg"}/>

                            <div className={"content"}>
                                1년 내내 K 문화의 장이 되어 언제나 모두에게 축제를 선사합니다.
                            </div>
                        </div>

                        <img src={"/image/detail/ksquare/main_image.png"}
                             className={"main-img"}/>

                        <div className={"grid-image"}>
                            <div className={"grid-title"}>
                                <img src={"/image/detail/ksquare/title_01.svg"}/>
                            </div>
                            <div className={"grid-img"}>
                                <div className={"grid-item"}>
                                    <img src={"/image/detail/ksquare/grid_01.png"}/>
                                    <div className={"title"}>Beauty</div>
                                    <ul>
                                        <li>K Cosmetics</li>
                                        <li>K Nail art / Hair shop</li>
                                        <li>K Make up Service</li>
                                        <li>K Beauty<br/>Live Commerce</li>
                                    </ul>
                                </div>
                                <div className={"grid-item"}>
                                    <img src={"/image/detail/ksquare/grid_02.png"}/>
                                    <div className={"title"}>Fashion</div>
                                    <ul>
                                        <li>K Style</li>
                                        <li>K Brand</li>
                                        <li>K Clothing<br/>Live Commerce</li>
                                    </ul>
                                </div>
                                <div className={"grid-item"}>
                                    <img src={"/image/detail/ksquare/grid_03.png"}/>
                                    <div className={"title"}>Contents</div>
                                    <ul>
                                        <li>K Movies</li>
                                        <li>K Dramas</li>
                                        <li>K Media Art</li>
                                        <li>K Pop Goods</li>
                                    </ul>
                                </div>
                                <div className={"grid-item"}>
                                    <img src={"/image/detail/ksquare/grid_04.png"}/>
                                    <div className={"title"}>Foods</div>
                                    <ul>
                                        <li>K Foods</li>
                                        <li>Dessert Cafe</li>
                                        <li>K Meal Kits<br/>(Cooking Box)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="banner">

                            <img src={"/image/detail/ksquare/banner.png"}/>
                            <div className="banner-text">
                                많은 관광객들을 타겟으로 K 문화에 대한<br/>다양한 체험 및 경험을 제공합니다.
                            </div>
                        </div>

                        <div className={"swiper-container"}>
                            <div className={"swiper-title-img"}>
                                <img src={"/image/detail/ksquare/title_02.svg"}/>
                            </div>
                            <div className={"swiper-area"}>

                                <div className={"swiper-title"}>
                                    <img src={"/image/detail/ksquare/swiper-top.png"}/>
                                </div>
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    navigation={true}
                                    pagination={true}
                                    modules={[Navigation, Pagination]}
                                >
                                    <SwiperSlide>
                                        <img src="/image/detail/ksquare/swiper_01.png"/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/image/detail/ksquare/swiper_02.png"/>
                                    </SwiperSlide>

                                </Swiper>
                            </div>

                            <div className={"swiper-area"}>

                                <div className={"swiper-title"}>
                                    <img src={"/image/detail/ksquare/swiper-bottom.png"}/>
                                </div>
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    navigation={true}
                                    pagination={true}
                                    modules={[Navigation, Pagination]}
                                >
                                    <SwiperSlide>
                                        <img src="/image/detail/ksquare/swiper_01.png"/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/image/detail/ksquare/swiper_02.png"/>
                                    </SwiperSlide>

                                </Swiper>
                            </div>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"studio-content three"}>
                        <div className={"studio-title"}>
                            <span>KOYO</span>를 통해<br/>
                            여러 기업들이 현지 진출을 위해 협력하고 있습니다.
                        </div>

                        <Swiper
                            slidesPerView={1.5}   // 초기값 설정, 모바일 기준
                            spaceBetween={10}
                            breakpoints={{
                                768: {
                                    slidesPerView: 2,  // 브라우저가 768px보다 클 때
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,  // 브라우저가 1024px보다 클 때
                                    spaceBetween: 50,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_01.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_02.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_03.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_04.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_05.png"}/>
                            </SwiperSlide>
                        </Swiper>

                        <div className={"text-content"}>
                            <div className={"_title"}>ARTICLES</div>
                            <div className={"_content"}>
                                <Link to={"https://www.woodkorea.co.kr/news/articleView.html?idxno=74814"}
                                      target={"_blank"}>고요컴퍼니, 자운결 화장품 브랜드와 업무 협약 체결</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.sisunnews.co.kr/news/articleView.html?idxno=188376"}
                                      target={"_blank"}>레아컴퍼니 자운결, 고요컴퍼니와 태국 K-Square 프로젝트 활성화 위한 MOU 체결</Link>
                            </div>
                            <div className={"_content"}>
                                <Link to={"https://www.dailysecu.com/news/articleView.html?idxno=149794"}
                                      target={"_blank"}>고요컴퍼니, 뷰티피플과 ‘K-Square’ 프로젝트 진행</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.ksilbo.co.kr/news/articleView.html?idxno=980643"}
                                      target={"_blank"}>고요컴퍼니, 마움과 ‘K-Square’ 프로젝트 진행</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.sisunnews.co.kr/news/articleView.html?idxno=200953"}
                                      target={"_blank"}>고요컴퍼니, 믹스앤매치(에스틸로)와 손잡고 ‘K-Square’ 프로젝트 도전</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.gokorea.kr/news/articleView.html?idxno=745076"}
                                      target={"_blank"}>고요컴퍼니, 크리에이티브위드와 태국에 ‘K-Square’ 구축 프로젝트</Link></div>
                        </div>

                        <div className={"text-bottom"}>
                            <span>K-Beauty</span>와 <span>K-Culture</span>의 동남아시아 진출을 함께합니다.
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </article>
    )
        ;
}

export default SectionTwo;
