import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide,} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import {Thumbs, Navigation} from 'swiper/modules';
import * as Io from "react-icons/io";

function SectionTwo({thumbsSwiper, setThumbsSwiper}) {
    const [imageWidth, setImageWidth] = useState(0);  // width 값을 저장하는 상태

    const handleImageLoad = (event) => {
        const {width} = event.target;
        setImageWidth(width);  // width 값만 저장
    };

    return (

        <article className="section02">
            <Swiper
                onSwiper={(swiper) => {
                    if (!thumbsSwiper) {
                        setThumbsSwiper(swiper);
                    }
                }}

                autoHeight={true}
                onSlideChange={() => {
                    window.scrollTo(0, 0); // 슬라이드가 변경될 때마다 페이지 상단으로 스크롤
                }}

                slidesPerView={1} // thumbsSwiper로 썸네일을 표시할 때 일반적인 슬라이드 효과 사용
                modules={[Thumbs]} // Thumbs 모듈만 적용
                className="fixed-card"
                onInit={() => {
                    console.log('Thumbs Swiper initialized');
                }}
                allowTouchMove={false} // 터치로 슬라이드 이동을 비활성화
                simulateTouch={false} // 터치 시뮬레이션 비활성화
                draggable={false} // 마우스로 드래그를 통한 슬라이드 이동 비활성화
            >
                <SwiperSlide>
                    <div className={"studio-content kevin"}>
                        <div className={"kevin-title"}>
                            <span className={"color"}>KEVIN PLACE</span> in <span className={"font"}>THAILAND</span>
                        </div>
                        <div className={"kevin-content"}>
                            다양한 시설을 갖춘 <span>3,000평 규모, 마켓과 마을</span>의 매력을<br/>
                            결합한 <span>새로운 형태의 복합 문화공간</span>을 선보입니다.
                        </div>
                        <div className={"breakpoint"}>
                            <img src={"/image/detail/kevinplace/image_content_01.png"}/>
                            <img src={"/image/detail/kevinplace/image_content_02.png"}/>
                            <img src={"/image/detail/kevinplace/image_content_03.png"}/>
                        </div>

                        <div className={"bg_img"}
                             style={{backgroundImage: "url('/image/detail/kevinplace/bg_image.png')"}}>
                            <img src={"/image/detail/kevinplace/image_content_04.png"}/>

                            <div className={"text"}>
                                옷 창고, 온/오프라인 판매 컨테이너, 먹거리 공간 및 플리마켓 공간 등의<br/>
                                다양한 시설을 오픈하고, 고객들에게 새로운 경험과 문화적 활동을 제공합니다.
                            </div>
                        </div>


                        <div className={"three_title"}>
                            KEVIN PLACE 내 KEVIN SHOP 컨셉 이미지
                        </div>
                        <div className={"three_image"}>
                            <img src={"/image/detail/kevinplace/last_image_01.png"}/>
                            <img src={"/image/detail/kevinplace/last_image_02.png"}/>
                            <img src={"/image/detail/kevinplace/last_image_03.png"}/>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </article>
    );
}

export default SectionTwo;
