import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide,} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import {Thumbs, Navigation} from 'swiper/modules';
import * as Io from "react-icons/io";
import * as Lu from "react-icons/lu";
import {Link} from "react-router-dom";

function SectionTwo({thumbsSwiper, setThumbsSwiper}) {
    const [imageWidth, setImageWidth] = useState(0);  // width 값을 저장하는 상태

    const handleImageLoad = (event) => {
        const {width} = event.target;
        setImageWidth(width);  // width 값만 저장
    };

    return (

        <article className="section02 news">
            <Swiper
                onSwiper={(swiper) => {
                    if (!thumbsSwiper) {
                        setThumbsSwiper(swiper);
                    }
                }}
                autoHeight={true}
                onSlideChange={() => {
                    window.scrollTo(0, 0); // 슬라이드가 변경될 때마다 페이지 상단으로 스크롤
                }}
                slidesPerView={1} // thumbsSwiper로 썸네일을 표시할 때 일반적인 슬라이드 효과 사용
                modules={[Thumbs]} // Thumbs 모듈만 적용
                className="fixed-card"
                onInit={() => {
                    console.log('Thumbs Swiper initialized');
                }}
                allowTouchMove={false} // 터치로 슬라이드 이동을 비활성화
                simulateTouch={false} // 터치 시뮬레이션 비활성화
                draggable={false} // 마우스로 드래그를 통한 슬라이드 이동 비활성화
            >
                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            BRANDING
                        </div>

                        <div className={"new-title"}>
                            고요컴퍼니, 틱톡 라이브커머스의 셀러 브랜딩 솔루션 제공
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_01.png"/>

                        <div className={"new-content"}>
                            국내 라이브커머스 시장은 포화상태다. 수많은 라이브플랫폼이 존재하는 가운데, 쇼호스트(셀러) 및 상품을 제공하는 기업에서는 어느 플랫폼에 라이브를 진행해야 할지
                            고민이 될
                            수밖에 없다. 플랫폼을 선택하기에 앞서 해당 플랫폼의 시청자, 이용자의 객관적인 정보와 수요를 확인해야 한다.<br/><br/>현재 라이브커머스 시장에는 그립,
                            네이버쇼핑라이브 등에서
                            활동하던 쇼호스트(셀러)와 기업들이 대거 틱톡으로 유입되고 있다. 이들은 틱톡의 글로벌 판매 가능성에 주목하며 새로운 기회를 모색하고 국내를 넘어 세계 시장으로의
                            영향력
                            확장을 노리고 있다.<br/><br/>이에 소셜 미디어 플랫폼인 틱톡에서 라이브커머스를 진행하고 있는 고요컴퍼니 측은 다양한 셀러 매니지먼트 와 자체상품 개발에
                            초점을 둔
                            브랜딩 솔루션을
                            제안한다고 밝혔다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 더퍼스트미디어<br/>2024.02.06 14:20 by 김대일 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.thefirstmedia.net/news/articleView.html?idxno=138212"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            ENTERTAINMENT ACTIVITIES
                        </div>

                        <div className={"new-title"}>
                            리사(RATTIKAN WAIPRIB) 고요컴퍼니 전속 계약 체결
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_02.png"/>

                        <div className={"new-content"}>
                            고요컴퍼니가 리사(RATTIKAN WAIPRIB)와 전속 계약을 체결했다.<br/><br/>
                            고요컴퍼니는 자사에서 전개하는 여성의류 브랜드에 리사를 모티브로 한 브랜드를 런칭하고,
                            모델로 발탁했다고 29일 밝혔다.<br/><br/>

                            브랜드 ‘고요 by 리사'는 아티스트의 스타일과 의견을 적극 반영하여 리사가 평소에 즐겨 입는 크롭티와, 프리사이즈 티로
                            출시하게 되었다. 평소 뛰어난 패션 감각과 스타일링을 보여준 리사의 이미지가 브랜드모델 선정으로 이어졌다.<br/><br/>

                            회사 관계자는 “고요컴퍼니는 의류 브랜드
                            외에도
                            향후 뷰티,
                            화장품, 라이프 스타일 제품을 아티스트 중심적으로 소통하고 제작하여, 다양한 분야로의 확장을 준비하고 있다”고 전했다.<br/><br/>또한 소속아티스트 ‘리사’는
                            향후 진행될
                            차기작 역시 긍정적으로 검토 중이며, 각종 드라마 촬영, 방송 촬영 등 다방면에서의 활발한 활동을 예고했다. 한편 리사는 가제-고요클로젯 웹드라마의 출연을 확정
                            짓고, 오는 8월 01일에 첫 촬영에 들어간다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : GLOBALEPIC<br/>2024-04-19 16:00 이수환 글로벌에픽 기자 lsh@globalepic.co.kr
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"http://www.kdpress.co.kr/news/articleView.html?idxno=120879"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            VOLUNTEER ACTIVITY
                        </div>

                        <div className={"new-title"}>
                            고요 회사, 태국 K-스튜디오 코퍼레이션 설립 및 글로벌 나눔 프로젝트 실시
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_03.png"/>

                        <div className={"new-content"}>
                            고요 회사가 태국에서 K-스튜디오를 설립하며 첫 번째 글로벌 나눔 프로젝트를 시작했다고 밝혔다.<br/><br/>
                            이는 한국 브랜드의 의류, 화장품, 그리고 밀키트
                            제품들이 동남아
                            시장에 효율적으로 진입할 수 있도록 지원하는 온라인 라이브 팝업 서비스다.<br/><br/>
                            더불어 첫 번째 글로벌 나눔 프로젝트를 실시했다. 태국의 가장 큰 명절인 송끄란은
                            한국의
                            설날과 유사하며 가족들이 모이는 즐거운 날로 고요 회사의 임원들과 현지 K-스튜디오 직원들은 송끄란을 즐길 수 없는 사람들과 함께 봉사 활동에
                            참여했다.<br/><br/>
                            코로나바이러스로 인한 관광업의 어려움을 겪고 있는 태국은 거리와 관광지에 노숙자 수가 증가했고 이에 고요 회사는 첫 봉사 활동으로 300명을 위한 도시락을 준비하고
                            배포했다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 더퍼스트미디어<br/>2024.02.06 14:20 by 김대일 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.globalepic.co.kr/view.php?ud=202404191105188356cf2d78c68_29"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            KOYO ACADEMY
                        </div>

                        <div className={"new-title"}>
                            고요컴퍼니의 태국 고요아카데미, 대표원장으로 이주선 단장 합류
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_04.png"/>

                        <div className={"new-content"}>
                            고요컴퍼니는 라이브커머스 및 엔터테인먼트 사업을 태국으로 확장하면서 국내 브랜드 의류, 화장품, 밀키트 등의 틱톡 라이브 팝업 사업을 진행하는 고요스튜디오와
                            인플루언서, 라이브커머스, 케이팝댄스, 한국어, 보컬 등 여러 교육을 배울 수 있는 고요아카데미 사업을 위한 법인을 설립하였다.
                            <br/><br/>
                            고요아카데미는 모든 교육과정의 끝에 국제표준 ISO자격증을 취득할 수 있는 전문 교육기관으로 자격증을 위한 교육뿐만 아니라 태국 현지 강사와 한국의 유명 강사진이
                            함께 실무에도 활용할 수 있는 수업을 진행한다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 국제뉴스<br/>2024.05.08 14:40 권찬민 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.gukjenews.com/news/articleView.html?idxno=2988737"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            ENTERTAINMENT
                        </div>

                        <div className={"new-title"}>
                            고요엔터테인먼트 이사 겸 인플루언서인 ‘비르’, 태국 글로벌 나눔 프로젝트 동참
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_05.png"/>

                        <div className={"new-content"}>
                            고요엔터테인먼트 이사 겸 유명 인플루언서인 ‘비르’는 최근 태국 법인 설립을 위해 출장 차 태국을 방문해 태국 현지 법인 K-Studio의 첫 번째 글로벌 나눔
                            프로젝트에 함께 참여헀다고 18일 밝혔다.
                            <br/><br/>
                            송크란은 태국의 최대 명절로 전 세계 사람들도 이 송크란을 즐기기 위해 태국을 방문하기도 한다. 그러나 이런 송크란을 가족도 없이 지내거나 배고픔을 견뎌야 하는
                            이들도 있어 ‘비르’도 함께 글로벌 나눔 프로젝트에 동참하게 됐다고 설명했다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : 비즈월드<br/>2024.04.18 17:04 정재훈 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.bizwnews.com/news/articleView.html?idxno=80990#google_vignette"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"news-content"}>
                        <div className={"new-category"}>
                            <Lu.LuCross/>
                            AGENCY
                        </div>

                        <div className={"new-title"}>
                            고요컴퍼니, 순이엔티와 틱톡 에이전시 계약 체결... 글로벌 시장 진출 가속화
                        </div>

                        <br/>

                        <img alt="사진=고요컴퍼니 제공"
                             src="/image/detail/news/news_06.png"/>

                        <div className={"new-content"}>
                            고요컴퍼니가 종합 MCN회사인 순이엔티와 손을 잡고 글로벌 틱톡 라이브커머스 시장을 공략한다고 발표했다.<br/><br/>
                            두 회사는 틱톡을 중심으로 한 라이브커머스와 엔터테인먼트 분야의 협력을 강화하고, 이를 통해 글로벌 시장 진출을 가속화하고자 한다고 밝혔다.
                            고요컴퍼니는 엔터테인먼트와 라이브커머스의 융합을 통해 소비자에게 새로운 경험을 제공하고, 글로벌 시장을 선도하는 종합 컨텐츠 기업을 지향한다.<br/><br/>이번
                            협력을
                            통해 고요컴퍼니 측은 태국 진출 추진 가속화를 기대하고 있으며, 디지털 미디어와 엔터테인먼트 분야에서의 경쟁력을 한층 끌어올릴 것으로 전망하고
                            있다.<br/><br/><br/>특히,
                            두 분야의 협력 강화를 통해 소비자들에게 보다 다채로운 콘텐츠를 제공할 수 있을 것으로 기대된다.<br/><br/>(요약)
                        </div>

                        <div className={"new-source"}>
                            출처 : CBC뉴스 | CBCNEWS(https://www.cbci.co.kr)<br/>2024.06.12 16:58 by 이종하 기자
                        </div>

                        <div className={"right"}>
                            <Link
                                to={"https://www.cbci.co.kr/news/articleView.html?idxno=469147#google_vignette"}
                                target={"_blank"} className={"linkBtn"}>
                                보도기사 자세히 보기
                                <Io.IoIosArrowRoundForward/>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>


            </Swiper>
        </article>
    )
        ;
}

export default SectionTwo;
