import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide,} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import {Thumbs, Navigation} from 'swiper/modules';
import * as Io from "react-icons/io";
import {Link} from "react-router-dom";

function SectionTwo({thumbsSwiper, setThumbsSwiper}) {
    const [imageWidth, setImageWidth] = useState(0);  // width 값을 저장하는 상태

    const handleImageLoad = (event) => {
        const {width} = event.target;
        setImageWidth(width);  // width 값만 저장
    };

    return (

        <article className="section02">
            <Swiper
                onSwiper={(swiper) => {
                    if (!thumbsSwiper) {
                        setThumbsSwiper(swiper);
                    }
                }}
                autoHeight={true}
                onSlideChange={() => {
                    window.scrollTo(0, 0); // 슬라이드가 변경될 때마다 페이지 상단으로 스크롤
                }}
                slidesPerView={1} // thumbsSwiper로 썸네일을 표시할 때 일반적인 슬라이드 효과 사용
                modules={[Thumbs]} // Thumbs 모듈만 적용
                className="fixed-card"
                onInit={() => {
                    console.log('Thumbs Swiper initialized');
                }}
                allowTouchMove={false} // 터치로 슬라이드 이동을 비활성화
                simulateTouch={false} // 터치 시뮬레이션 비활성화
                draggable={false} // 마우스로 드래그를 통한 슬라이드 이동 비활성화
            >
                <SwiperSlide>
                    <div className={"studio-content first"}>
                        <div className={"studio-title"}>
                            <img src={"/image/detail/studio/title_01.png"}/>
                        </div>
                        <div className={"studio-content-text"}>
                            Live Commerce Off-line Store를 통해 설득력 있는 제품 판매의<br/>
                            과정 및 방법을 투명하게 지원하고, 원활한 셀러 활동을 위한 유용한<br/>
                            정보 및 커뮤니티를 만듭니다.
                        </div>

                        <div className={"studio-image-grid"}>
                            <img src={"/image/detail/studio/image_01.png"}/>
                            <div className={"_grid-image"}>
                                <img src={"/image/detail/studio/image_02.png"}/>
                                <img src={"/image/detail/studio/image_03.png"}/>
                            </div>
                        </div>
                        <div className={"studio-image-grid-02"}>
                            <img src={"/image/detail/studio/image_04.png"}/>
                            <div className={"studio-text"}>
                                <span>의류, 화장품, 액세서리</span> 등 다양한 제품을 <br/>
                                셀러에게 제공하여 내외 시장에 판매하고, <br/>
                                자체 브랜드 상품도 개발하여 소개할 계획<br/>입니다.
                            </div>
                        </div>

                        <div className={"studio-image-grid-03"}>
                            <img src={"/image/detail/studio/image_05.png"}/>
                            <img src={"/image/detail/studio/image_06.png"}/>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className={"studio-content seconds"}>
                        <div className={"studio-title"}>
                            <img src={"/image/detail/studio/title_02.png"}/>
                        </div>
                        <div className={"studio-content-text"}>
                            Live Commerce Pop-up Store를 통해 국내 중소기업의<br/>
                            태국 시장 판로 확장과 인플루언서의 전문적인 육성과 영역<br/>
                            확장을 서포트합니다.
                        </div>

                        <div className={"studio-image-grid"}>
                            <div className={"_grid-image"}>
                                <img src={"/image/detail/studio/image_07.png"}/>
                                <img src={"/image/detail/studio/image_08.png"}/>
                            </div>
                            <img src={"/image/detail/studio/image_09.png"}/>

                        </div>
                        <div className={"studio-image-grid-02 otherImage"}>
                            <img src={"/image/detail/studio/image_10.png"}/>
                        </div>

                        <div className={"studio-text"}>
                            <span>브랜드 팝업 매장</span> 형태의 <span>LIVE STUDIO</span>로 브랜드 상품, 풀필먼트,<br/>
                            라이브 커머스, 아카데미, 매니지먼트를 연결하는 통합 솔루션입니다.
                        </div>
                        <div className={"studio-image-grid-03"}>
                            <img src={"/image/detail/studio/image_11.png"}/>

                            <div className={"text"}>
                                기존 팝업 스토어의 형식을 온라인 공간에서 즐길 수 있는 <span>체험형 콘텐츠</span>로,<br/>
                                라이브 스트리밍을 통해 한국의 화장품, 의류, 밀키트 등의 제품을 소개하고<br/>
                                판매하는 <span>새로운 형태와 컨셉의 팝업 스토어</span>입니다.
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"studio-content three"}>
                        <div className={"studio-title"}>
                            <img src={"/image/detail/studio/title_03.png"}/>
                        </div>
                        <div className={"studio-content-text"}>
                            KOYO를 통해 여러 기업들이 현지 진출을 위해 협력하고 있습니다.
                        </div>

                        <Swiper
                            slidesPerView={1.5}   // 초기값 설정, 모바일 기준
                            spaceBetween={10}
                            breakpoints={{
                                768: {
                                    slidesPerView: 2,  // 브라우저가 768px보다 클 때
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,  // 브라우저가 1024px보다 클 때
                                    spaceBetween: 50,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_01.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_02.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_03.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_04.png"}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={"/image/detail/studio/logo_05.png"}/>
                            </SwiperSlide>
                        </Swiper>


                        <div className={"text-content"}>
                            <div className={"_title"}>ARTICLES</div>
                            <div className={"_content"}>
                                <Link to={"https://www.woodkorea.co.kr/news/articleView.html?idxno=74814"}
                                      target={"_blank"}>고요컴퍼니, 자운결 화장품 브랜드와 업무 협약 체결</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.sisunnews.co.kr/news/articleView.html?idxno=188376"}
                                      target={"_blank"}>레아컴퍼니 자운결, 고요컴퍼니와 태국 K-Square 프로젝트 활성화 위한 MOU 체결</Link>
                            </div>
                            <div className={"_content"}>
                                <Link to={"https://www.dailysecu.com/news/articleView.html?idxno=149794"}
                                      target={"_blank"}>고요컴퍼니, 뷰티피플과 ‘K-Square’ 프로젝트 진행</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.ksilbo.co.kr/news/articleView.html?idxno=980643"}
                                      target={"_blank"}>고요컴퍼니, 마움과 ‘K-Square’ 프로젝트 진행</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.sisunnews.co.kr/news/articleView.html?idxno=200953"}
                                      target={"_blank"}>고요컴퍼니, 믹스앤매치(에스틸로)와 손잡고 ‘K-Square’ 프로젝트 도전</Link></div>
                            <div className={"_content"}>
                                <Link to={"https://www.gokorea.kr/news/articleView.html?idxno=745076"}
                                      target={"_blank"}>고요컴퍼니, 크리에이티브위드와 태국에 ‘K-Square’ 구축 프로젝트</Link></div>
                        </div>

                        <div className={"text-bottom"}>
                            <span>K-Beauty</span>와 <span>K-Culture</span>의 동남아시아 진출을 함께합니다.
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </article>
    )
        ;
}

export default SectionTwo;
