import React, {useRef} from "react";
import "view/style/scss/header.scss";
import {Link} from "react-router-dom";

function Header({...props}) {
    return (
        <React.Fragment>
            <header className="header">
                <Link to={"/"}>
                    <img src={"/image/logo.svg"}/>
                </Link>

            </header>
        </React.Fragment>
    );
}

export default Header;
